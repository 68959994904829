.projects {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .tech-list {
    display: flex;
    margin-top: 5px;
    flex-wrap: wrap;

    .tech {
      padding: 2px 5px;
      background: lightgrey;
      border-radius: 20px;
      color: white;
      font-size: 80%;
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }

  .project-item-wrapper {
    flex: 0 0 auto;
    max-width: 100%;

    .project-item {
      padding: 5px;

      .content {
        background: white;
        box-shadow: 1px 2px 6px lightgrey;
        border-radius: 20px;
        padding: 20px;

        .project-title {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          .label {
            font-size: 150%;
          }

          .date {
            font-size: 80%;
            color: lightgrey;
          }
        }

        .project-description {
          font-size: 80%;
          color: darkgray;
        }

        .project-details {
          .tech-list {
            display: flex;
            margin-top: 5px;
            flex-wrap: wrap;

            .tech {
              padding: 2px 5px;
              background: lightgrey;
              border-radius: 20px;
              color: white;
              font-size: 80%;
              margin-right: 5px;
              margin-bottom: 5px;
            }
          }

          .images {
            display: flex;
            flex-direction: row;
            overflow-y: scroll;

            .holder {
              position: relative;
              display: block;
              overflow: hidden;
              border-radius: 10px;
              width: 200px;
              height: 200px;
              margin-right: 10px;

              .img {
                width: 100%;
                height: 100%;
                background-position: center;
                background-size: cover;
              }

              .overlay {
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                color: white;
                background: rgba(0,0,0,0.7);
                place-content: center;
                justify-content: center;
              }

              &:hover {
                .overlay {
                  display: flex;
                  align-content: center;
                  align-items: center;
                }
              }
            }
          }

          .company {
            span {
              margin-right: 10px;
            }
          }

          .platforms {
            margin-top: 5px;
            display: flex;
            align-items: center;

            .label {
              margin-right: 10px;
            }

            .platform {
              margin-right: 5px;
            }

            a {
              &:hover {
                -webkit-filter: drop-shadow(1px 2px 8px darkgray);
                filter: drop-shadow(1px 2px 8px darkgray);
              }
            }
          }

          .responsibilities {
            display: flex;

            .resp-title {
              margin-right: 10px;
            }

            .resp {
              padding: 2px 5px;
              background: lightgrey;
              border-radius: 20px;
              color: white;
              font-size: 80%;
              margin-right: 5px;
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }
}